import React from 'react';
import Calculadora from './calculadora';
import Calendario from './calendario';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  HashRouter
} from "react-router-dom";


function App() {
  return (
    <div className="App">
     <HashRouter>
      <Switch>
          <Route path="/calendario">
            <Calendario />
          </Route>
          <Route path="/">
            <Calculadora />
          </Route>
        </Switch>
    </HashRouter>
    </div> 
  );
}

export default App;
