import React from 'react';
import numeral from 'numeral';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    PieChart, Pie, Sector, Cell, Bar, ResponsiveContainer
} from 'recharts';


numeral.register('locale', 'pt-br', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'R',
        million: 'R',
        billion: 'R',
        trillion: 'R'
    },
    currency: {
        symbol: 'R$'
    }
});

numeral.locale('pt-br');


const COLORS = ['#427bbe', '#ed7d31'];

const COLORS1 = ['#ed7d31', '#427bbe'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {/* {`${(percent * 100).toFixed(0)}%`} */}
    </text>
  );
};

export default class Calculadora extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formatMoney: { minimumFractionDigits: 2 , currency: 'BRL' },
            radioEscolha: '',
            dataGrafico:[],
            b3: 120,
            b5:1450,
            c5:0,
            d5:0,
            e5:0,

            b6:25,
            c6:0,
            d6:0,
            e6:0,

            b7:60,
            c7:0,
            d7:0,
            e7:0,

            b8:4,
            c8:0,
            d8:0,
            e8:0,

            b10:420,
            c10:0,
            d10:0,
            e10:0,

            b11:80,
            c11:0,
            d11:0,
            e11:0,

            b12:80,
            c12:0,
            d12:0,
            e12:0,

            b15:0,
            c15:0,
            d15:0,
            e15:0,
            
            
            f17: 0,
            f19: 0,
            f20: 0,

            f24: 22*10*60,
            f25: 0,
            f26: 0,

            h10:0,
            h12:0,

            l24: 22*10*60,
            
            //Rental
            l15:0,

            //Investimento
            q9:180000,
            //Aumento Lucratividade
            aumentoLucratividade: 0,
            payBack: 0,
            economia: 0,
            aumentoAtendimento: 0,
            dataGraficoPizza1:[],
            dataGraficoPizza2:[]
        };

        this.calculate = this.calculate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        
    }
    
    componentDidMount() {
        this.calculate();
    }

    calculoArray(initial) {
        let total = initial;
        let array = [];
            array[0] = total;
        for(let i = 1; i <= 11; i++) {
            array[i] = array[i - 1] + array[0];
        }
        return array;
    }

    calculate() {
         this.setState({
            b14:this.state.b6*this.state.b5,
            c14:this.state.c6*this.state.c5,
            d14:this.state.d6*this.state.d5,
            e14:this.state.e6*this.state.e5,
            
            b16: ((this.state.b10 + this.state.b11 + this.state.b12) * this.state.b6) + ((this.state.b3 * this.state.b7/60)*this.state.b6*this.state.b8),
            
            c16: ((this.state.c10 + this.state.c11 + this.state.c12) * this.state.c6) + ((this.state.b3 * this.state.c7/60)*this.state.c6*this.state.c8),

            d16: ((this.state.d10 + this.state.d11 + this.state.d12) * this.state.d6) + ((this.state.b3 * this.state.d7/60)*this.state.d6*this.state.d8),

            e16: ((this.state.e10 + this.state.e11 + this.state.e12) * this.state.e6) + ((this.state.b3 * this.state.d7/60)*this.state.e6*this.state.e8),
        }, () => {
            this.totalReceita();
        });
    }
    
    totalReceita() {
        this.setState({
            f14: this.state.b14 + this.state.c14 + this.state.d14 + this.state.e14
        }, () => {
            this.totalRental();
        });
    }

    totalRental() {
        this.setState({
            f15: this.state.b15 + this.state.c15 + this.state.d15 + this.state.e15,
        },() => {
            this.totalProducao();
        });
    }
    
    totalProducao() {
        this.setState({
            f16: this.state.b16 + this.state.c16 + this.state.d16 + this.state.e16,
        }, () => {
            this.margem();
        });
    }

    margem() {
        this.setState({
            b17:this.state.b14 - this.state.b16,
            c17:this.state.c14 - this.state.c16,
            d17:this.state.d14 - this.state.d16,
            e17:this.state.e14 - this.state.e16,
        }, () => {
            this.totalMargem();
        });
    }
    
    totalMargem(){
        this.setState({
            f17: (this.state.b17 + this.state.c17 + this.state.d17 + this.state.e17) - this.state.f15
        }, () => {
            this.tempoTrabalho();
        });
    }

    tempoTrabalho() {
        this.setState({
            b19:this.state.b6 * this.state.b7 * this.state.b8,
            c19:this.state.c6 * this.state.c7 * this.state.c8,
            d19:this.state.d6 * this.state.d7 * this.state.d8,
            e19:this.state.e6 * this.state.e7 * this.state.e8,
        }, () => {
            this.totalTempoTrabalho();
        });
    }

    totalTempoTrabalho() {
        this.setState({
            f19:this.state.b19 + this.state.c19 + this.state.d19 + this.state.e19,
        }, () => {
            this.tempoAtendimento();
        });
    }

    tempoAtendimento() {
        this.setState({
            b20:this.state.b7 * this.state.b8,
            c20:this.state.c7 * this.state.c8,
            d20:this.state.d7 * this.state.d8,
            e20:this.state.e7 * this.state.e8,
        }, () => {
            this.totalTempoAtendimento();
        });
    }

    totalTempoAtendimento() {
        this.setState({
            f20: (this.state.b20 + this.state.c20 + this.state.d20 + this.state.e20) / 4
        }, () => {
            this.tempoEmClinica();
        });
    }

    tempoEmClinica() {
        this.setState({
            f25: this.state.f19
        }, () => {
            this.tempoLivre();
        });
    }

    tempoLivre() {
        this.setState({
            f26: this.state.f24 - this.state.f25
        }, () => {
            this.solucaoDigitalInlay();
        });
    }

    solucaoDigitalInlay() {
        this.setState({
            h3:this.state.b3, 
            h5:this.state.b5,
            h6:this.state.b6,
            h7:this.state.b7 + 60,
            h8:1,
            h11:100
        }, () => {
            this.solucaoDigitalCoroa();
        });
    }

    solucaoDigitalCoroa() {
        this.setState({
            i5:this.state.c5,
            i6:this.state.c6,
            i7:this.state.c7 + 60,
            i8:1,
            i9:0,
            i10:0,
            i11:this.state.h11,
            i12:0
        }, () => {
            this.solucaoDigitalPontes();
        });
    }

    solucaoDigitalPontes() {
        this.setState({
            j5:this.state.d5,
            j6:this.state.d6,
            j7:this.state.d7 + 60,
            j8:1,
            j9:0,
            j10:0,
            j11:this.state.i11,
            j12:0
        }, () => {
            this.solucaoDigitalOrto();
        });
    }

    solucaoDigitalOrto() {
        this.setState({
            k5:this.state.e5,
            k6:this.state.e6,
            k7:this.state.e7 + 60,
            k8:1,
            k9:0,
            k10:0,
            k11:this.state.j11,
            k12:0
        }, () => {
            this.receitaDigital();
        });
    }

    receitaDigital() {
        this.setState({
            h14:this.state.h6 * this.state.h5,
            i14:this.state.i6 * this.state.i5,
            j14:this.state.j6 * this.state.j5,
            k14:this.state.k6 * this.state.k5,
            
        }, () => {
            this.receitaDigitalTotal();
        });
    }
    
    receitaDigitalTotal() {
        this.setState({
            l14:this.state.h14 + this.state.i14 + this.state.j14 + this.state.k14,
        }, () => {
            this.economiaGanhoReceitaDigital();
        });
    }

    economiaGanhoReceitaDigital() {
        this.setState({
            m14:this.state.l14 - this.state.f14,
        }, () => {
            this.rentalDigital();
        });
    }

    rentalDigital() {
        this.setState({
            l15: (this.state.radioEscolha) ? 7000 : 0,
        }, () => {
            this.economiaGanhoRentalDigital();
        });
    }

    economiaGanhoRentalDigital() {
        this.setState({
            m15:this.state.l15 - this.state.f15,
        }, () => {
            this.producaoDigital();
        });
    }

    producaoDigital() {
        this.setState({
            h16:((this.state.h10 + this.state.h11 + this.state.h12) * this.state.h6) + ((this.state.b3 * this.state.h7/60)*this.state.h6*this.state.h8),
            i16:((this.state.i10 + this.state.i11 + this.state.i12) * this.state.i6) + ((this.state.b3 * this.state.i7/60)*this.state.i6*this.state.i8),
            j16:((this.state.j10 + this.state.j11 + this.state.j12) * this.state.j6) + ((this.state.b3 * this.state.j7/60)*this.state.j6*this.state.j8),
            k16:((this.state.k10 + this.state.k11 + this.state.k12) * this.state.k6) + ((this.state.b3 * this.state.k7/60)*this.state.k6*this.state.k8),
        }, () => {
            this.producaoDigitalTotal();
        });
    }

    producaoDigitalTotal() {
        this.setState({
            l16:(this.state.h16 + this.state.i16 + this.state.j16 + this.state.k16),
        }, () => {
            this.producaoDigitalEconomiaGanho();
            //this.margemDigital();
        });
    }

    producaoDigitalEconomiaGanho() {
        this.setState({
            m16:(this.state.l16 - this.state.f16),
        }, () => {
            this.margemDigital();
        });
    }

    margemDigital() {
        this.setState({
            h17:this.state.h14 - this.state.h16,
            i17:this.state.i14 - this.state.i16,
            j17:this.state.j14 - this.state.j16,
            k17:this.state.k14 - this.state.k16,
        }, () => {
            this.margemDigitalTotal();
        });
    }

    margemDigitalTotal() {
        this.setState({
            l17:(this.state.h17 + this.state.i17 + this.state.j17 + this.state.k17) - this.state.l15,
        }, () => {
            this.margemDigitalEconomiaGanho();
        });
    }

    margemDigitalEconomiaGanho(){
        this.setState({
            m17:this.state.l17 - this.state.f17,
        }, () => {
            this.tempoTrabalhoDigital();
        });
    }
    
    tempoTrabalhoDigital() {
        this.setState({
            h19:this.state.h6 * this.state.h7 * this.state.h8,
            i19:this.state.i6 * this.state.i7 * this.state.i8,
            j19:this.state.j6 * this.state.j7 * this.state.j8,
            k19:this.state.k6 * this.state.k7 * this.state.k8
        }, () => {
            this.tempoTrabalhoDigitalTotal();
        });
    }
    tempoTrabalhoDigitalTotal() {
        this.setState({
            l19:(this.state.h19 + this.state.i19 + this.state.j19 + this.state.k19),
        }, () => {
            this.tempoTrabalhoDigitalEconomiaGanho();
        });
    }

    tempoTrabalhoDigitalEconomiaGanho() {
        this.setState({
            m19:(this.state.f19-this.state.l19)/this.state.f19,
        }, () => {
            this.tempoAtendimentoDigital();
        });
    }

    tempoAtendimentoDigital() {
        this.setState({
            h20:this.state.h7 * this.state.h8,
            i20:this.state.i7 * this.state.i8,
            j20:this.state.j7 * this.state.j8,
            k20:this.state.k7 * this.state.k8
        }, () => {
            this.tempoAtendimentoDigitalTotal();
        });
    }

    tempoAtendimentoDigitalTotal() {
        this.setState({
            l20:(this.state.h20 + this.state.i20 + this.state.j20 + this.state.k20) / 4,
        }, () => {
            this.tempoAtendimentoDigitalEconomiaGanho();
        });
    }

    tempoAtendimentoDigitalEconomiaGanho() {
        this.setState({
            m20: ((this.state.f19 - this.state.l19)/ this.state.l20)/(this.state.b6 + this.state.c6 + this.state.d6 + this.state.e6)
        }, () => {
            this.projecaoTradicional();
        });
    }

    projecaoTradicional() {
        this.setState({
            p4: this.state.f17
        }, () => {
            this.projecaoTradicionalValores(this.calculoArray(this.state.p4));
        });
    }

    projecaoTradicionalValores(arr){
        this.setState({
            aa4: arr[arr.length - 1]
        }, () => {
            this.projecaoStraumann();
        })
    }

    projecaoStraumann() {
        this.setState({
            p5: this.state.l17
        }, () => {
            this.projecaoStraumannValores(this.calculoArray(this.state.p5));
        });
    }
    
    projecaoStraumannValores(arr){
        this.setState({
            aa5: arr[arr.length - 1]
        }, () => {
            this.aumentoLucratividade();
        })
    }

    aumentoLucratividade(){
        this.setState({
            q10: (this.state.aa5 / this.state.aa4 -1),
            aumentoLucratividade: Math.round((this.state.aa5 / this.state.aa4 -1) * 100)
        }, () => {
            this.payBack();
        })
    }

    payBack(){
        this.setState({
            q11:Math.ceil(this.state.q9 / this.state.m17),
            payBack: Math.ceil(this.state.q9 / this.state.m17),
        }, () => {
            this.economia();
        })
    }

    economia() {
        this.setState({
            economia: Math.round(this.state.m19 * 100),
        }, () => {
            this.aumentoAtendimento();
        })
    }

    aumentoAtendimento() {
        this.setState({
            aumentoAtendimento: Math.round(this.state.m20 * 100),
        }, () => {
            this.setGrafico();
        })
    }

    formatNumber(number) {
       return new Intl.NumberFormat('pt-BR', this.state.formatMoney).format(number);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        if (target.type === 'text'){
            this.setState({
                [name]: numeral(value).value()
            }, () => {
                this.calculate();
            });
        }
        else{
            
            this.setState({
                [name]: value
            }, () => {
                this.calculate();
            });
        }
    }

    setGrafico() {
        
        let antigo = this.calculoArray(this.state.p5);
        let atual = this.calculoArray(this.state.p4);

        let arrayGraf = [];
        atual.map((item, index) => {
            arrayGraf.push({
                tradicional: item,
                straumann: antigo[index],
                amt: item,
            });
            //numeral(item).format()
        });

        this.setState({
            dataGrafico:arrayGraf
        }, () => {
            this.setGraficoPizza();
        })
    }

    setGraficoPizza() {
        this.setState({
            dataGraficoPizza1: [
                { name: 'Group A', value: this.state.f25 },
                { name: 'Group B', value: this.state.f26 }
            ],
            dataGraficoPizza2: [
                { value: this.state.l19 },
                { value: this.state.l24 - this.state.l19 }
            ]
        });
    }


    render() {
    return <div>
        {/* <picture className="hidden-print">
            <img src="style/index_files/banner.jpg" class="img-responsive banner" alt="" />
        </picture> */}
        <div
        className="container"
        >
            <div>
                <h2 className="margin-title">Faça uma análise de seu investimento na solução Digital Straumann e veja os benefícios que a solução digital pode lhe oferecer.</h2>
            </div>
            <div
            className="alert alert-info alert-dismissible"
            role="alert"
            >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
                    Este recurso de análise é apenas para simulação. Seu resultado é somente uma estimativa e
                    depende exclusivamente das informações preenchidas. Não nos responsabilizamos pelo
                    mesmo.
            </div>
            
            <div className="row d-flex graficos-print">
                <div className="col-sm-4">
                
                    <LineChart
                        width={370}
                        height={250}
                        data={this.state.dataGrafico}
                        margin={{top: 5, right: 30, left: 20, bottom: 5}}
                        style={{marginTop: '25px', zIndex: '99'}}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Legend />
                        <Line type="monotone" dataKey="straumann" stroke="#8884d8" activeDot={{r: 8}}/>
                        <Line type="monotone" dataKey="tradicional" stroke="#82ca9d" />
                    </LineChart>
                
                </div>
                <div className="col-sm-3 align-center flex-direction">
                    <h4>Tradicional</h4>
                    <PieChart width={250} height={250}>
                        <Pie
                        data={this.state.dataGraficoPizza1}
                        cx={125}
                        cy={125}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        >
                        {
                            this.state.dataGraficoPizza1.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />)
                        }
                        </Pie>
                    </PieChart>
                </div>
                <div className="col-sm-2 align-center">
                    <div className="legenda">
                        <span>
                            <div className="tempoClinica"></div>Tempo em clínica
                        </span>
                        <span>
                            <div className="tempoLivre"></div>
                            Tempo livre
                        </span>
                    </div>
                </div>
                <div className="col-sm-3 align-center flex-direction">
                    <h4>Straumann</h4>
                    <PieChart width={250} height={250}>
                        <Pie
                        data={this.state.dataGraficoPizza2}
                        cx={125}
                        cy={125}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        >
                        {
                            this.state.dataGraficoPizza2.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                        </Pie>
                    </PieChart>
                </div>
            </div>
                
            <div
            className="col-md-8 col-sm-8 col-2"
            >
                <div className="table">
                <table
                    className="hidden-print table table-bordered table-striped"
                    >
                        <tbody>
                        <tr>
                                <td style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '90px'
                                    }}>
                                    <div className="input-group input-group-radio">
                                        <label className="radio-escolha">
                                            <input
                                            type="checkbox"
                                            value="rental"
                                            name="radioEscolha"
                                            checked={this.state.radioEscolha}
                                            onChange={this.handleInputChange}
                                            />
                                            <span>Opção de Rental</span>
                                        </label>
                                    </div>
                                </td>
                                <td style={{width:'390px'}}>
                                    <p style={{fontSize: '15px', fontWeight: 'bold'}}>Com a opção Rental não há necessidade de altos investimentos. Entre em contato e descubra nossos benefícios.</p>                            
                                </td>
                            </tr>
                        <tr>
                                <th scope="row">
                                    <span className="valorHora">Valor médio da hora/tratamento da sua clínica</span>
                                </th>
                                <td>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            R$
                                        </div>
                                        <input
                                        type="text"
                                        className="form-control au-target"
                                        placeholder="00,00"
                                        name="b3"
                                        value={numeral(this.state.b3).format()}
                                        onChange={this.handleInputChange}
                                        />
                                    </div>
                                </td>
                            </tr>
                        {/* <tr>
                                <th scope="row" colspan="2">
                                    <span className="">Rental ou Compra direta?</span>
                                </th>
                            </tr> */}
                            
                        </tbody>
                    </table>
       

                    <table
                    className="hidden-print table table-bordered table-striped"
                    >
                    <thead>
                        <tr>
                            <th>DADOS DA CLÍNICA</th>
                            <th>INLAY / ONLAY</th>
                            <th>COROA TOTAL / PARCIAL</th>
                            <th>PONTES</th>
                            <th>MOLDES ORTO</th>
                        </tr>
                    </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    Valor médio do tratamento
                                </th>
                                <td>
                                    <div className="input-group">
                                    <div className="input-group-addon">
                                        R$
                                    </div>
                                    <input
                                    type="text"
                                    className="form-control au-target"
                                    placeholder="00,00"
                                    name="b5"
                                    value={numeral(this.state.b5).format()}
                                    onChange={this.handleInputChange}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div className="input-group">
                                        <div
                                        className="input-group-addon"
                                        >
                                        R$
                                        </div>
                                        <input
                                        type="text"
                                        className="form-control au-target"
                                        placeholder="00,00"
                                        name="c5"
                                        value={numeral(this.state.c5).format()}
                                        onChange={this.handleInputChange}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="input-group">
                                    <div className="input-group-addon">
                                    R$
                                    </div>
                                    <input
                                    type="text"
                                    className="form-control au-target"
                                    placeholder="00,00"
                                    name="d5"
                                    value={numeral(this.state.d5).format()}
                                    onChange={this.handleInputChange}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div className="input-group">
                                    <div
                                    className="input-group-addon"
                                    >
                                    R$
                                    </div>
                                    <input
                                    type="text"
                                    className="form-control au-target"
                                    placeholder="00,00"
                                    name="e5"
                                    value={numeral(this.state.e5).format()}
                                    onChange={this.handleInputChange}
                                    />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            <th scope="row">
                                Quantidade de procedimentos mensais
                            </th>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="teste "
                                name="b6"
                                value={numeral(this.state.b6).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="c6"
                                value={numeral(this.state.c6).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="d6"
                                value={numeral(this.state.d6).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="e6"
                                value={numeral(this.state.e6).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            </tr>
                        <tr>
                            <th scope="row">
                                Tempo médio por consulta(minutos)
                            </th>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="b7"
                                value={numeral(this.state.b7).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="c7"
                                value={numeral(this.state.c7).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="d7"
                                value={numeral(this.state.d7).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="e7"
                                value={numeral(this.state.e7).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Quantidade de consulta para cada tratamento
                            </th>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="b8"
                                value={numeral(this.state.b8).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="c8"
                                value={numeral(this.state.c8).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="d8"
                                value={numeral(this.state.d8).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                className="form-control au-target"
                                placeholder="00,00"
                                name="e8"
                                value={numeral(this.state.e8).format()}
                                onChange={this.handleInputChange}
                                />
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div className="hidden-print">
                        <div className="form-group">
                            <table className="table table-striped visible-print-block">
                                <thead>
                                    <tr>
                                        <th>GASTOS COM LABORATÓRIO</th>
                                        <th>INLAY / ONLAY</th>
                                        <th>COROA TOTAL / PARCIAL</th>
                                        <th>PONTES</th>
                                        <th>MOLDES ORTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Valor do laboratório</th>
                                        <td>R$ 400</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Valor por peça provisória</th>
                                        <td>R$ 100</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Valor por moldagem</th>
                                        <td>R$ 60</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                        <td>R$ 0</td>
                                    </tr>
                                </tbody>
                            </table>
                      <table className="hidden-print table table-bordered table-striped">
                          <thead>
                              <tr>
                                <th>GASTOS COM LABORATÓRIO</th>
                                <th>INLAY / ONLAY</th>
                                <th>COROA TOTAL / PARCIAL</th>
                                <th>PONTES</th>
                                <th>MOLDES ORTO</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <th scope="row">Custo do laboratório</th>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="b10"
                                            value={numeral(this.state.b10).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="c10"
                                            value={numeral(this.state.c10).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="d10"
                                            value={numeral(this.state.d10).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="e10"
                                            value={numeral(this.state.e10).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <th scope="row">Custo por peça provisória</th>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="b11"
                                            value={numeral(this.state.b11).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="c11"
                                            value={numeral(this.state.c11).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="d11"
                                            value={numeral(this.state.d11).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="e11"
                                            value={numeral(this.state.e11).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <th scope="row">Custo por moldagem</th>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="b12"
                                            value={numeral(this.state.b12).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="c12"
                                            value={numeral(this.state.c12).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="d12"
                                            value={numeral(this.state.d12).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div className="input-group">
                                          <div className="input-group-addon">R$</div>
                                          <input
                                            type="text"
                                            className="form-control au-target"
                                            placeholder="00,00"
                                            name="e12"
                                            value={numeral(this.state.e12).format()}
                                            onChange={this.handleInputChange}
                                          />
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>

                      
                  </div>
                  <button
                  type="button" 
                  onClick={() => window.print()}
                  className="btn btn-block btn-default btn-lg hidden-print">Imprimir PDF <i className="fas fa-print"></i></button>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-2">
				<div className="row d-flex line-result-title">
					<div className="col-md-2 col-sm-2 text-center">
						<div className="icon-result">
							<i className="fas fa-chart-line"></i>
						</div>
					</div>
					<div className="col-md-10 col-sm-10 align-center">
					  <h3 className="text-result">ANÁLISE INVESTIMENTO</h3>
                  </div>
				</div>

				<div className="row">
					<div className="col-md-12 col-sm-12 text-center">
						<div className="panel panel-default" style={{border:"2px dotted #427bbe"}}>
							<div className="panel-body">
							Você poderá ter o retorno do que investiu em até <strong>{this.state.payBack} meses</strong>, sem comprometer seu resultado atual.
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-sm-12 text-center">
                        <div className="panel panel-default" 
                            style={{
                                border:"2px dotted #427bbe"
                            }}
                        >
							<div className="panel-body">
							Poderá ter um aumento em <b>{this.state.aumentoLucratividade}%</b> na lucratividade em relação ao método atual.

                            {this.state.radioEscolha && <span>O retorno simulado já considera a parcela do rental.</span>}
							</div>
						</div>
					</div>
				</div>
				<div className="row d-flex line-result-title">
					<div className="col-md-2 col-sm-2 text-center">
						<div className="icon-result">
							<i className="fas fa-chart-bar"></i>
						</div>
					</div>
					<div className="col-md-10 col-sm-10 align-center">
						<h3 className="text-result">ANÁLISE DE TEMPO</h3>
					</div>
				</div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                      <div className="panel panel-default"
                        style={{border:"2px dotted #427bbe"}}>
                          <div className="panel-body">
								Com a solução Digital Straumann, você poderá ter uma economia de <b>{this.state.economia}%</b> do seu tempo em sua clínica, em comparação ao método tradicional.
                          </div>
					  </div>
                      <div className="panel panel-default"
                      style={{border: "2px dotted #427bbe"}}>
							<div className="panel-body">
									Proporcionando mais tempo livre para suas atividades fora da clínica, ou ainda, podendo ter um aumento de <b>{this.state.aumentoAtendimento}%</b> de atendimento com método Digital Straumann.
							</div>
						</div>
                  </div>
                </div>
            </div>
        </div>
    </div>;
  }
}
