import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timegrid from '@fullcalendar/timegrid';
import timeline from '@fullcalendar/timeline';
import axios from 'axios';
import renderHTML from 'react-render-html';

import { directive } from '@babel/types';

export default class Calendario extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            events: [],
            legends: []
        };
    
    }

    componentDidMount() {
        axios.get(`https://cors-anywhere.herokuapp.com/http://191.232.35.45/api/calendar`)
            .then(res => {
                const events = res.data.data;
                this.setState({ events });
                // this.state.data.map(function(item, i){
                //     console.log('test');
                //     return <li key={i}>Test</li>
                //   })
                
            });

        axios.get(`https://cors-anywhere.herokuapp.com/http://191.232.35.45/api/calendar/legend`)
        .then(res => {
            const legends = res.data.data;
            this.setState({ legends });
        });

            
    }

    
    
  render() {


    const getStyle = (i) => {

        return {
            overflow: this.state.clickShow === i? 'auto' : '',
            maxHeight: this.state.clickShow === i? 'initial' : ''
        }
    }
    
    const showMore = (i) => {
        this.setState({clickShow:i});
    }


    return (
    <>
        {/* <h1>Calendário de cursos</h1> */}
            
        
            <p className="text-center">Conheça novas tecnologias, experimente nossos produtos, softwares, consumíveis e aumente seu networking participando de nossos cursos e treinamentos online e presenciais.</p>

            <h4 className="margin-title text-center">Esperamos você!</h4>
        

        {/* <p className="message-course">Veja como se registrar acessando a página de cada curso.*</p> */}
        <div className="container">
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1">
            <FullCalendar 
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, list, timegrid ]}
            // navLinks="true"
            //timeZone={'UTC'}
            header={{
            left: '',
            center: 'title',
            right: 'listYear,dayGridMonth,dayGridWeek,prev,next'
            }}
            locale="pt-br"
            displayEventTime={false}
            views={{
                listYear: {
                    buttonText: 'Ano'
                },
                dayGridMonth: {
                    buttonText: 'Mês'
                },
                dayGridWeek: {
                    buttonText: 'Semana'
                },
            }}
            events={this.state.events}
            />
        </div>
        </div>
        </div>
      <div className="container">
        <div className="row">
            
            {
            this.state.legends.map((item, i) => {

                return (
                
                    <div className="col-sm-4 padding-40 box-legenda" key={i} style={getStyle(i)}>
                        <h5 
                        className="title-event"
                        style={{backgroundColor: item.color }}
                        >
                        {item.title}
                        </h5>
                        {renderHTML(item.description)}
                        {
                        this.state.clickShow != i &&
                        <span className="click-show" onClick={() => showMore(i)}>LEIA MAIS</span>
                        }
                    </div>
                )
              })
            }
        </div> 

      </div>
    </>
    )
  }

}